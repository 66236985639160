import dayjs from 'dayjs'
import * as yup from 'yup'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {toast} from 'react-toastify'
import {ParagraphText, Input, Button, Badge, Modal} from '@thryvlabs/maverick'

import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import {ResultsTable} from '../components/results-table'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {AdminContainer, InputsContainer} from '../components/ui/admin'

const SALES_REP_INFO_DEFAULT_STATE = {
  firstName: '',
  lastName: '',
  email: '',
}

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be a valid email')
    .min(3, 'Email must be at least 3 characters')
    .max(100, 'Email cannot be longer than 100 characters')
    .required(),
  username: yup
    .string()
    .min(3, 'Username must be at least 3 characters')
    .max(100, 'Username cannot be longer than 100 characters')
    .required(),
  firstName: yup
    .string()
    .min(3, 'First Name must be at least 3 characters')
    .max(100, 'First Name cannot be longer than 100 characters')
    .required(),
  lastName: yup
    .string()
    .min(3, 'Last Name must be at least 3 characters')
    .max(100, 'Last Name cannot be longer than 100 characters')
    .required(),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(100, 'Password cannot be longer than 100 characters')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
})

const keapUserformSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be a valid email')
    .min(3, 'Email must be at least 3 characters')
    .max(100, 'Email cannot be longer than 100 characters')
    .required(),
  firstName: yup
    .string()
    .min(3, 'First Name must be at least 3 characters')
    .max(100, 'First Name cannot be longer than 100 characters')
    .required(),
  lastName: yup
    .string()
    .min(3, 'Last Name must be at least 3 characters')
    .max(100, 'Last Name cannot be longer than 100 characters')
    .required(),
  sfUserId: yup
    .string()
    .min(3, 'sfUserId must be at least 3 characters')
    .max(100, 'sfUserId cannot be longer than 100 characters'),
  keapPartnerId: yup
    .string()
    .min(3, 'keapPartnerId must be at least 3 characters')
    .max(100, 'keapPartnerId cannot be longer than 100 characters'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .max(100, 'Password cannot be longer than 100 characters')
    .required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords does not match')
    .required(),
})
const parseRoles = ({roles}) => {
  return roles.map(role => {
    const splittedRole = role.split('-')
    return splittedRole[splittedRole.length - 1]
  })
}

const SearchInput = ({
  url,
  setResponse,
  inputPlaceHolder,
  buttonText,
  isQueryParam = false,
  inputType = 'text',
  showErrorMessage = true,
}) => {
  const [query, setQuery] = useState('')
  const client = useAuthClient()
  const {run, error, isError, setError, isLoading} = useAsync()

  const handleOnChange = ({target: {value}}) => {
    setQuery(value)
  }
  const handleOnKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = async () => {
    try {
      if (query.length === 0) {
        toast.warning('Enter a valid value.')
        return
      }
      if (
        inputType === 'email' &&
        !query.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)
      ) {
        toast.warning('Enter a valid email.')
        return
      }
      let response
      if (isQueryParam) {
        response = await run(client(`${url}?email=${query}`))
      } else {
        response = await run(client(`${url}/${query}`))
      }
      setResponse({response})
    } catch (error) {
      if (error.status === 500) {
        setError({
          ...error,
          message: 'No results were found.',
        })
      }
      setResponse({error})
    }
  }
  return (
    <div className="text-center">
      <div className="d-flex m-2">
        <Input
          className="w-100 mr-4"
          type="text"
          placeholder={inputPlaceHolder}
          aria-label={inputPlaceHolder}
          withLabel
          labelType="floating"
          variant="default"
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
          value={query}
        />
        <div className="d-flex align-items-center">
          <Button
            variant="primary"
            className="mr-4"
            onClick={handleSearch}
            disabled={isLoading}
          >
            {buttonText}
          </Button>
        </div>
      </div>
      {isError && showErrorMessage && (
        <ParagraphText variant="lg" className="mb-0 text-thryv-stee">
          <strong>{error.message}</strong>
        </ParagraphText>
      )}
    </div>
  )
}

const CreateUserModal = () => {
  const client = useAuthClient()
  const {run, isLoading} = useAsync()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
  })

  const handleCreateUser = async data => {
    try {
      await run(client('user/', {data}))
      toast.success('User was created.')
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Modal
      variant="default"
      title="Create Reseller User"
      btnText="Create Reseller User"
      btnType="primary"
    >
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Email"
          aria-label="email"
          name="email"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Username"
          aria-label="username"
          name="username"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="First Name"
          aria-label="firstName"
          name="firstName"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Last Name"
          aria-label="lastName"
          name="lastName"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Password"
          aria-label="password"
          name="password"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>

      <div className="w-100 text-center">
        <Button
          variant="primary"
          className="mr-4"
          onClick={handleSubmit(handleCreateUser)}
          disabled={isLoading}
        >
          Create
        </Button>
      </div>
    </Modal>
  )
}

const CreateNewKeapPartnerModal = () => {
  const client = useAuthClient()
  const {run, isLoading} = useAsync()
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(keapUserformSchema),
  })

  const handleCreateUsers = async data => {
    try {
      const response = await run(client('user/createkeappartneruser', {data}))
      if (response.hasWarningorError) {
        toast.error(response.data.message)
      } else {
        toast.success('User was created.')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <Modal
      variant="default"
      title="Create Keap User"
      btnText="Create Keap User"
      btnType="primary"
    >
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="First Name"
          aria-label="First Name"
          register={register}
          errors={errors}
          name="firstName"
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Last Name"
          aria-label="lastName"
          register={register}
          errors={errors}
          name="lastName"
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Username"
          aria-label="username"
          name="username"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
          errors={errors}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Email"
          aria-label="Email"
          errors={errors}
          name="email"
          withLabel
          labelType="floating"
          variant="default"
          register={register}
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="Keap Partner ID"
          aria-label="Keap Partner ID"
          name="keapPartnerId"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="text"
          placeholder="SF User ID"
          aria-label="SF User ID"
          name="sfUserId"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="password"
          placeholder="Password"
          aria-label="Password"
          name="password"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="mt-4 mb-3">
        <Input
          className="w-100"
          type="password"
          placeholder="Confirm Password"
          aria-label="Confirm Password"
          name="confirmPassword"
          register={register}
          errors={errors}
          withLabel
          labelType="floating"
          variant="default"
        />
      </div>
      <div className="w-100 text-center">
        <Button
          onClick={handleSubmit(handleCreateUsers)}
          variant="primary"
          className="mr-4"
          disabled={isLoading}
        >
          create
        </Button>
      </div>
    </Modal>
  )
}

const Admin = () => {
  const [salesRepInfo, setSalesRepInfo] = useState(SALES_REP_INFO_DEFAULT_STATE)
  const [userInfo, setUserInfo] = useState({})
  const lDFlags = useFlags()

  const validateOnBehalf = ({response, error}) => {
    if (error) {
      toast.error(error.message)
      setSalesRepInfo(SALES_REP_INFO_DEFAULT_STATE)
      return
    }
    let responseObject = response
    if (response instanceof Array) {
      responseObject = response[0]
    }
    const {rep_first_name, rep_last_name, rep_email} = responseObject
    setSalesRepInfo({
      firstName: rep_first_name,
      lastName: rep_last_name,
      email: rep_email,
    })
  }

  const validateProposalId = async ({response, error}) => {
    if (error) {
      toast.error(error.message)
      return
    }
    if (response.isValidProposal) {
      toast.success('Valid proposal ID provided.')
    }
  }

  const validateThryvLeadId = ({response, error}) => {
    if (error) {
      toast.error(error.message)
      return
    }
    if (response.passed) {
      toast.success('Valid campaign ID provided.')
    } else {
      toast.error(`Error: ${response.reason}.`)
    }
  }

  const validateUser = ({response, error}) => {
    if (error) {
      setUserInfo({})
      if (error.errors) {
        if (error.errors[0]?.type === 'string.email') {
          toast.error('The entered value must be a valid email.')
          return
        }
      }
      toast.error(error.message)
      return
    }
    const {NetworkID, firstname, lastname, last_login, groups} = response
    setUserInfo({
      networkID: NetworkID,
      firstName: firstname,
      lastName: lastname,
      roles: parseRoles({roles: groups}),
      lastLoginDate: last_login,
    })
  }

  return (
    <AdminContainer className="p-4 m-5">
      <InputsContainer className="mb-5">
        <div className="text-center">
          <SearchInput
            url="orders/onbehalf"
            setResponse={validateOnBehalf}
            inputPlaceHolder="Lookup OnBehalf"
            buttonText="Validate"
          />
          {salesRepInfo.firstName && (
            <ParagraphText variant="lg" className="mb-0 text-thryv-stee">
              <strong>{`${salesRepInfo.firstName} ${salesRepInfo.lastName}`}</strong>
            </ParagraphText>
          )}
        </div>
        <div className="d-flex align-items-center">
          <CreateUserModal />
        </div>
        <div className="d-flex align-items-center">
          <CreateNewKeapPartnerModal />
        </div>
      </InputsContainer>

      {lDFlags.frontEndUtilityPageAdmin && (
        <>
          <hr />
          <section className="mt-5">
            <InputsContainer className="mb-5">
              <SearchInput
                url="user"
                setResponse={validateUser}
                inputPlaceHolder="Validate user email"
                buttonText="Validate"
                isQueryParam={true}
                inputType="email"
                showErrorMessage={false}
              />
              <SearchInput
                url="plans/validate-thryv-leads"
                setResponse={validateThryvLeadId}
                inputPlaceHolder="Validate Thryv Lead ID"
                buttonText="Validate"
              />
              <SearchInput
                url="orders/validate-proposal"
                setResponse={validateProposalId}
                inputPlaceHolder="Validate SEO Lead ID"
                buttonText="Validate"
              />
            </InputsContainer>
          </section>
          <ResultsTable
            cols={[
              'NetworkId',
              'First Name',
              'Last Name',
              'Roles',
              'Last Login Date',
            ]}
            isLoading={false}
            hasRecords={userInfo.networkID}
          >
            <tr>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {userInfo.networkID}
                </span>
              </td>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {userInfo.firstName}
                </span>
              </td>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {userInfo.lastName}
                </span>
              </td>
              <td>
                {userInfo.roles && (
                  <div className="roles-container">
                    {userInfo.roles.map(role => (
                      <Badge
                        key={role}
                        variant="text"
                        badgeText={role}
                        className="m-1"
                      />
                    ))}
                  </div>
                )}
              </td>
              <td>
                <span className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                  {dayjs(userInfo.lastLoginDate).format('MM/DD/YYYY HH:MM:ss')}
                </span>
              </td>
            </tr>
          </ResultsTable>
        </>
      )}
    </AdminContainer>
  )
}

export default Admin
